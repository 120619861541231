import {
  TextField,
  Box,
  Grid,
  Typography,
  IconButton,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
  Link,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";
import "./Emailpage.css";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Common/Loader/Loader";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { sendEmail } from "../../Services/Actions/actions";
import { postService } from "../../config/auth";
import { styled } from "@mui/system";
import UserImage from "../../Assets/Images/user-palceholder.jpg";
import { PersonData } from "../../Common/PersonData";
import {
  setHeaderStepFourCompleted,
  setLoader,
} from "../../Services/Actions/actionCreators";

const StyledButton = styled(Button)({
  textTransform: "none",
});



export default function Emailpage(props: any) {
  const { emailApiResponce, domainType }: any = props;
  const navigate = useNavigate();
  const selectedPersons = useSelector(
    (state: any) => state.selectedPersonData.selectedPersons
  );
  const mailDetail = useSelector((state: any) => state.emailReducer.mailDetail);
  const loaderStore = useSelector((state: any) => state.loaderReducer?.loader);
  const [selectedPersonIndex, setSelectedPersonIndex] = useState(0);
  const [EmailData, setEmailData] = useState<any>({});
  const [showLoader, setShowLoader] = useState<any>(false);
  const [selectedPerson, setSelectedPerson] = useState(
    selectedPersons[selectedPersonIndex]
  );

  /* istanbul ignore next */
  const onSendEmail = async () => {
    const payload = emailApiResponce.data.map((item: any, index: any) => ({
      subject: item?.subject_email,
      to_email: item?.email,
      owner_email: item?.owner_email,
      text: item?.cust_email,
      follow_up_number: item?.follow_up_number,
      format_type: item?.format_type,
      name: item?.name,
      owner_name: item?.owner_name,
      queue_name: item?.queue_name,
      user_name: item?.user_name,
    }));

    const campaignPayload = {
      campaignName: domainType,
      campaignSource: "salesforce"
    };

    setShowLoader(true);

    try {
      await Promise.all([
        sendEmail(payload),
        fetch("https://mm-track-dev.compunnel.com/tracker/insertCampaignData", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(campaignPayload)
        })
      ]);

      toast.success("Mail sent successfully.");
      navigate("/mail-sent");
    } catch (error) {
      console.error("Error sending email or inserting campaign data:", error);
      toast.error("Failed to send email or update campaign data.");
    } finally {
      setShowLoader(false);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
    dispatch(setHeaderStepFourCompleted());
  };
const dispatch = useDispatch();
  const handleClick = () => {
    props.setMailView(false);
  };

  useEffect(() => {
    if (emailApiResponce?.data?.length) {
      let data = emailApiResponce?.data.findIndex(
        (val: any) => val.email === selectedPersons[selectedPersonIndex]?.email
      );
      setEmailData(emailApiResponce.data[data]);
    }
  }, [emailApiResponce]);

  useEffect(() => {
    if (emailApiResponce && selectedPersons?.length > 0) {
      // Find index of the selected person's email in the emailApiResponce data
      const data = emailApiResponce.data?.findIndex(
        (val: any) => val.email === selectedPersons[selectedPersonIndex]?.email
      );

      if (data !== -1 && data !== undefined) {
        // Set the email data from the found index or fallback to an empty string
        setEmailData(emailApiResponce.data[data]);
      } else {
        setEmailData("");
      }

      // Set the currently selected person
      setSelectedPerson(selectedPersons[selectedPersonIndex]);
    } else {
      console.warn("Email API response or selected persons list is missing.");
    }
  }, [emailApiResponce, selectedPersonIndex, selectedPersons]);

  useEffect(() => {
    if (selectedPersons?.length) {
      setSelectedPerson(selectedPersons[selectedPersonIndex]);
    }
  }, [selectedPersonIndex, selectedPersons]);


  const handleEmailChange = (e: any) => {
    const { name, value } = e.target;
    setEmailData({
      ...EmailData,
      [name]: value,
    });
  };

  /* istanbul ignore next */
  return loaderStore ? (
    <Loader />
  ) : (
    <Box sx={{ width: "100%", marginBottom: "20px" }}>
      <Box className="comp-box-header">
        <Grid container={true} spacing={2} className="table-box custom-space">
          <Grid item={true} xs={3}>
            {/* <IconButton onClick={handleClick} className="custom-heading">
              <ArrowBackIosIcon /> Back to Generate Email
            </IconButton> */}
            <Box className="custom-div-grid1">
              <Box className="custom-pic-box">
                <img
                  src={selectedPerson?.image}
                  alt="Profile-pic"
                  className="profile-picture"
                  onError={(
                    e: React.SyntheticEvent<HTMLImageElement, Event>
                  ) => {
                    if (e.target) {
                      e.currentTarget.src = UserImage;
                    }
                  }}
                />
              </Box>

              <Box className="custom-heading-box">
                <Typography
                  variant="h5"
                  fontWeight={700}
                  className="custom-heading"
                >
                  {selectedPerson?.data?.full_name}
                </Typography>
              </Box>
              <Box>
                <Box className="first-grid-text">
                  <Typography className="first-grid-headings">
                    Full Name
                  </Typography>
                  <span>{selectedPerson?.data?.full_name}</span>
                </Box>
                <Box className="first-grid-text">
                  <Typography className="first-grid-headings">
                    Job Title
                  </Typography>
                  <span>{selectedPerson?.data?.occupation}</span>
                </Box>
                <Box className="first-grid-text">
                  <Typography className="first-grid-headings">Email</Typography>
                  <Link href={selectedPerson?.email}>
                    {selectedPerson?.email}
                  </Link>
                </Box>
                <Box className="first-grid-text">
                  <Typography className="first-grid-headings">
                    LinkedIn Profile URL
                  </Typography>

                  <Link href={selectedPerson?.linkedInURL}>
                    {selectedPerson?.linkedInURL}
                  </Link>
                </Box>
                <Box className="first-grid-text">
                  <Typography className="first-grid-headings">City</Typography>
                  <span>{selectedPerson?.data?.city}</span>
                </Box>
                <Box className="first-grid-text">
                  <Typography className="first-grid-headings">
                    State/Region
                  </Typography>
                  <span>{selectedPerson?.data?.state}</span>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item={true} xs={6} className="Custom-TextFieldbox">
            <Box>
              <Typography
                variant="h5"
                fontWeight={700}
                className="custom-heading"
              >
                Personalised mail for{" "}
                <span className="head-blue-text">
                  {" "}
                  {selectedPerson?.data?.full_name}
                </span>
              </Typography>
              <Typography variant="h6" fontWeight={700}>
                To:{" "}
              </Typography>
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                value={EmailData?.name ?? mailDetail?.name}
                className="Custom-textfield"
                data-testid="to"
              />
            </Box>
            <Box>
              <Typography
                variant="h6"
                fontWeight={700}
                className="custom-heading"
              >
                Subject:
              </Typography>{" "}
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                value={EmailData?.subject_email ?? mailDetail?.subject_email}
                className="Custom-textfield"
                name="subject_email"
                onChange={handleEmailChange}
                data-testid="sub"
              />
            </Box>
            <Box>
              <Typography
                variant="h6"
                fontWeight={700}
                className="custom-heading"
              >
                Message:
              </Typography>{" "}
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                value={EmailData?.cust_email ?? mailDetail?.cust_email}
                className="custom-mail-box"
                multiline={true}
                name="cust_email"
                onChange={handleEmailChange}
                data-testid="msg"
              />
            </Box>
            <Box>
              <StyledButton
                variant="contained"
                className="send-btn btn-gld"
                onClick={onSendEmail}
              >
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"baseline"}
                >
                  Send Email {showLoader && <CircularProgress size={"1.3em"} />}
                </Box>
              </StyledButton>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box className="custom-div-radio">
              <Box className="box-hdr-txt">
                <Typography variant="h5" className="custom-heading">
                  Profiles
                </Typography>
              </Box>
              <Divider className="custom-divider-email" />

              <Box className="radio-box">
                <FormControl component="fieldset">
                  <RadioGroup
                    value={selectedPersonIndex.toString()}
                    onChange={(event) =>
                      setSelectedPersonIndex(Number(event.target.value))
                    }
                  >
                    {selectedPersons.map((person: any, index: any) => (
                      <FormControlLabel
                        key={person?.data?.email}
                        value={index}
                        control={<Radio />}
                        label={person?.data.full_name}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

