 /* istanbul ignore file */

import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { useRef } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import "./LeftNavigation.css";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useState } from "react";
import AutomatedIcon from "../../Assets/Icons/AutomatedFollupIcon.svg";
import { DashboardIcon } from "../../Assets/Icons/DashboardIcon";
import MailReviewIcon from "../../Assets/Icons/MailReviewIcon.svg";
import UnsubscribeIcon from "../../Assets/Icons/unsubscribeIcon.svg";
import LogoutIcon from "../../Assets/Icons/LogoutIcon.svg";

const drawerWidth = 179;
const collapsedWidth = 100;
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));



const LeftNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const drawerRef = useRef<HTMLDivElement>(null);

  const handleLogOut = () => {
    localStorage.clear();
    navigate("/");
    setOpen(!open)
  };



  const renderDrawer = () => (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: open ? drawerWidth : collapsedWidth,
          boxSizing: "border-box",
          display: "flex",
        },
      }}
      variant="persistent"
      anchor="left"
      ref={drawerRef}
      open={open}
    >
      <DrawerHeader
        className="custom-drower-header"
        style={{ marginRight: "20px" }}
      >
        <Tooltip title={"Menu"} placement="right-end">
          <IconButton
            className="desktopMenuIconbtn"
          >
            <MenuIcon style={{ color: "#fff" }} />
          </IconButton>
        </Tooltip>
        <Typography className="header-font"> Menu</Typography>
      </DrawerHeader>
      <Divider className="sidebar-devider-main" />
      <Box className="custom-main-list">
        <List className="custom-list">
          <ListItem
            disablePadding
            className="custom-listItem-header"
            onClick={() => navigate("/dashboard")}
          >
            <ListItemButton
              sx={{
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                backgroundColor: location.pathname === "/dashboard" ? "#303cad" : "inherit",
              }}
            >
              <DashboardIcon />
              <ListItemText
                primary="Dashboard"
                className="drower-text"
              />
            </ListItemButton>
          </ListItem>
          <Divider className="sidebar-devider" />
          
          <ListItem
            disablePadding
            className="custom-listItem-header"
            onClick={() => navigate("/automated-followUp")}
          >
            <ListItemButton
              sx={{
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                backgroundColor: location.pathname === "/automated-followUp" ? "#303cad" : "inherit",
              }}
            // onClick={handleDrawerClose}
            >
              <img src={AutomatedIcon} />
              <ListItemText
                primary="Automated Follow Up Settings"
                className="drower-text"
              />
            </ListItemButton>
          </ListItem>
          <Divider className="sidebar-devider" />

          <ListItem
            disablePadding
            className="custom-listItem-header"
            onClick={() => navigate("/mail-review")}
          >
            <ListItemButton
              sx={{
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                backgroundColor: location.pathname === "/mail-review" ? "#303cad" : "inherit",
              }}
            >
              <img src={MailReviewIcon} />
              <ListItemText primary="Email Review" className="drower-text" />
            </ListItemButton>
          </ListItem>
          <Divider className="sidebar-devider" />
              
          <ListItem
            disablePadding
            className="custom-listItem-header"
            onClick={() => navigate("/unsubscribe-followUp")}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                backgroundColor: location.pathname === "/unsubscribe-followUp" ? "#303cad" : "inherit",
              }}
            >
              <img src={UnsubscribeIcon} style={{ color: "white" }} />
              <ListItemText
                primary="Unsubscribed Follow Up"
                className="drower-text"
              />
            </ListItemButton>
          </ListItem>
          <Divider className="sidebar-devider" />
          <ListItem
            disablePadding
            className="custom-listItem-header"
            onClick={() => navigate("/campaigns")}
          >
            <ListItemButton
              sx={{
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                backgroundColor: location.pathname === "/campaigns" ? "#303cad" : "inherit",
              }}
            >
              <DashboardIcon />
              <ListItemText
                primary="Manage Campaigns"
                className="drower-text"
              />
            </ListItemButton>
          </ListItem>
          <Divider className="sidebar-devider" />
          <ListItem
            disablePadding
            className="custom-listItem-header"
            onClick={() =>{
               navigate("/hubspot")
               window.location.reload()
              }}
          >
            <ListItemButton
              sx={{
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                backgroundColor: location.pathname === "/hubspot" ? "#303cad" : "inherit",
              }}
            >
              <img src={AutomatedIcon} />
              <ListItemText
                primary="Run Campaigns"
                className="drower-text"
              />
            </ListItemButton>
          </ListItem>
          <Divider className="sidebar-devider" />
        </List>

        <List className="custom-list2">
          <Divider className="sidebar-devider" />
          <ListItem
            disablePadding
            className="custom-listItem-header"
            onClick={handleLogOut}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              data-testid="logout-btn"
            >
              <img src={LogoutIcon} style={{ color: "white" }} />
              <ListItemText primary="Logout" className="drower-text" />
            </ListItemButton>
          </ListItem>
          <Divider className="sidebar-devider" />

          <ListItem
            disablePadding
            className="custom-listItem-header"
            sx={{ marginBottom: "auto" }}
          >
            <ListItemText
              primary="Version 2.1"
              className="drower-text-2"
              color="white"
            />
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );

  return (
    <div className="leftNavigationMenu">
      {renderDrawer()}
    </div>
  )
}

export default LeftNavigation
