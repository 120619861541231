
import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TRACKER_API_ENDPOINTS } from "../../config/auth"

interface DataRow {
  email: string;
  delivered: number;
  open: number;
  clicked: number;
  unsubscribe: number;
  total: number;
}

const CampRecordTable = () => {
  const [data, setData] = useState<DataRow[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(TRACKER_API_ENDPOINTS.EVENT_SCORE_BY_EMAIL);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const jsonData = await response.json();
      setData(jsonData);
    } catch (error) {
      setError('Failed to fetch data. Please try again later.');
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell colSpan={6} align="left"><b>MM Score</b></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Email ID</TableCell>
            <TableCell align="right">Delivered</TableCell>
            <TableCell align="right">Opened</TableCell>
            <TableCell align="right">Clicked</TableCell>
            <TableCell align="right">Unsubscribed</TableCell>
            <TableCell align="right">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {error ? (
            <TableRow>
              <TableCell colSpan={6} align="center">{error}</TableCell>
            </TableRow>
          ) : (
            data.map((row) => (
              <TableRow key={row.email}>
                <TableCell component="th" scope="row">
                  {/* {row.email} */}
                </TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                {/* <TableCell align="right">{row.delivered + 10}</TableCell>
                <TableCell align="right">{row.open + 10}</TableCell>
                <TableCell align="right">{row.clicked + 10}</TableCell>
                <TableCell align="right">{row.unsubscribe + 10}</TableCell>
                <TableCell align="right">{row.total + 10}</TableCell> */}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CampRecordTable;
