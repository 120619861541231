import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import BlockIcon from '@mui/icons-material/Block';

import CheckCircleIcon from '@mui/icons-material/Block';
import "./Campaigns.css";
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  TextField,
  Button,
  Box,
  Typography,
  TextareaAutosize,
  Chip,
  Avatar,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  IconButton,
  Paper,
  CssBaseline,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Container,
} from "@mui/material";

import { Add, Delete, Visibility, CloudUpload } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Loader from "../../Common/Loader/Loader";
import searchScope from "../../Assets/Icons/searchScope.svg";
import axios from "axios";
import { BASE_URL } from "../../config/auth";
import ConfirmationDialog from "../../Common/ConfiramtionModal/confirmationDialog";
import Pagination from "../../Common/Pagination/Pagination";
import { useSelector } from "react-redux";
interface Campaign {
  audience_count: number;
  created_at: string;
  id: number;
  name: string;
  description: string;
  lastrandate: string;
  filename: string | null;
  status: string;
}

const Campaigns: React.FC = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false); // State to manage dialog visibility
  const [campaignName, setCampaignName] = useState("");
  const [campaignDescription, setCampaignDescription] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [selectedCampaignId, setSelectedCampaignId] = useState<any>();
  const loaderStore = useSelector((state: any) => state.loaderReducer?.loader);
  const token = localStorage.getItem("token");

  
  const navigate = useNavigate();
  const [pageIndex, setPageIndex] = useState(0);
  const [page, setPage] = useState(0);
  const [totalContactsNumber, setTotalContactsNumber] = useState(0);
  const [value, setValue] = useState("");
  const pageSize = 5;

  useEffect(() => {
    fetchCampaignList();
  }, [pageIndex, page]);

  const handleNext = (event: any, newPage: number) => {
    setPageIndex(newPage);
    setPage(newPage);
  };

  const openConfiramtionModal = (id: number) => {
    setSelectedCampaignId(id);
    setOpenConfirmationDialog(true);
  };
  const handleToggleCampaignStatus = (id: number, newStatus: string) => {
    axios
      .post(
        `${BASE_URL}/update_campaign_status`,
        { action: "update_status", campaign_id: id, status: newStatus, updated_by: 1 },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response: any) => {
        if (response?.response && response?.response.status === 400) {
          toast.error(response?.response.data);
        } else {
          // Update the status locally for immediate UI feedback
          setCampaigns((prevCampaigns) =>
            prevCampaigns.map((campaign) =>
              campaign.id === id ? { ...campaign, status: newStatus } : campaign
            )
          );
          toast.success(
            `Campaign marked as ${newStatus === "inactive" ? "inactive" : "active"} successfully.`
          );
        }
      })
      .catch((error) => {
        console.error("Error updating campaign status:", error);
        toast.error("Failed to update campaign status.");
      });
  };
  const handleMarkAsInactive = (id: number) => {
    // Mark the campaign as inactive
    axios
      .post(
        `${BASE_URL}/update_campaign_status`,
        { action: "mark_inactive", campaign_id: id, updated_by: 1 },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response: any) => {
        if (response?.response && response?.response.status === 400) {
          toast.error(response?.response.data);
        } else {
          // Update the status locally for immediate effect
          setCampaigns((prevCampaigns) =>
            prevCampaigns.map((campaign) =>
              campaign.id === id ? { ...campaign, status: "inactive" } : campaign
            )
          );
          toast.success("Campaign marked as inactive successfully.");
          setSelectedCampaignId(null);
          setOpenConfirmationDialog(false);
        }
      })
      .catch((error) => {
        console.error("Error marking campaign as inactive:", error);
        toast.error("Failed to mark campaign as inactive.");
      });
  };
  const handleChangeStatus = () => {
    const newStatus = selectedCampaignId === 'active' ? 'inactive' : 'active';
  
    axios
      .post(
        `${BASE_URL}/update_campaign_status`,
        { campaign_id: selectedCampaignId, status: newStatus },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success('Campaign status updated successfully');
  
        // Update local state immediately for better user experience
        setCampaigns((prevCampaigns) =>
          prevCampaigns.map((campaign) =>
            campaign.id === selectedCampaignId
              ? { ...campaign, status: newStatus }
              : campaign
          )
        );
  
        fetchCampaignList(); // Optional: Fetch again to ensure full sync
        setOpenConfirmationDialog(false);
      })
      .catch((error) => {
        console.error('Error updating campaign status:', error);
        toast.error('Failed to update campaign status');
      });
  };
  

  const handleDeleteCampaign = (id: number) => {
    // Handle campaign deletion logic here
    axios
      .post(
        `${BASE_URL}/delete_campaign`,
        { action: "delete", campaign_id: id, deleted_by: 1 },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response: any) => {
        if (response?.response && response?.response.status == 400) {
          toast.error(response?.response.data);
        } else {
          fetchCampaignList();
          toast.success(response?.data);
          setSelectedCampaignId(null);
          setOpenConfirmationDialog(false);
        }
        // Handle success response
      })
      .catch((error) => {
        console.error("Error adding campaign:", error);
      });
  };

  const handleSearch = async () => {
    if (value) {
      fetchCampaignList();
    } else if (value == ""){
      fetchCampaignList()
    }

  };

  useEffect(() => {
    handleSearch()
  }, [])
  

  const fetchCampaignList = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/camp_contact_data`,
        {
          action: "campaign_list",
          campaign_name: "",
          searchValue: value,
          cursor: pageIndex,
          offset: pageSize,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCampaigns(response.data.data || []);
      setTotalContactsNumber(response?.data?.total_records);
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    }
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleAddCampaign = async () => {
    const formData = new FormData();
    formData.append("campaignName", campaignName);
    formData.append("campaignDescription", campaignDescription);
  
    if (file) {
      formData.append("uploadFile", file);
    }
  
    try {
      const response = await axios.post(`${BASE_URL}/csvupload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`, // Add authorization header
        },
      });
  
      if (response.data.statuscode === 200 || response.data.statuscode === 203) {
        toast.success(response.data.message);
        fetchCampaignList();
        setOpenDialog(false); // Close the dialog after successful submission
        setCampaignName("");
        setCampaignDescription("");
        setFile(null);
      } else {
        toast.error(response.data || "Failed to add campaign");
      }
    } catch (error: any) {
      console.error("Error adding campaign:", error);
      if (error.response) {
        toast.error(error.response.data.message || "Failed to add campaign");
      } else if (error.request) {
        toast.error("No response from server");
      } else {
        toast.error("Error adding campaign");
      }
    }
  };
  const isFormValid = campaignName && campaignDescription && file;

  return (
    <Box padding={3}>
      <Box className="custom-hdr">
      <Typography variant="h4" align="center" gutterBottom>
        Campaign Management
      </Typography>

      {/* Add Campaign Button */}
      {/* <Button
        variant="contained"
        color="primary"
        startIcon={<Add />}
        onClick={() => setOpenDialog(true)} // Open dialog
      >
        Add Campaign
      </Button> */}

      {/* Dialog for Adding Campaign */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Add New Campaign</DialogTitle>
        <DialogContent>
          <TextField
            label="Campaign Name"
            value={campaignName}
            onChange={(e) => setCampaignName(e.target.value)}
            fullWidth
            required
            margin="dense"
          />
          <TextField
            label="Campaign Description"
            value={campaignDescription}
            onChange={(e) => setCampaignDescription(e.target.value)}
            fullWidth
            multiline
            rows={4}
            required
            margin="dense"
          />
          <Button
            variant="outlined"
            component="label"
            startIcon={<CloudUpload />}
            sx={{ mt: 2 }}
          >
            Upload File
            <input
              type="file"
              hidden
              onChange={handleFileUpload}
              accept=".xlsx, .csv"
            />
          </Button>
          <Typography sx={{ mt: 1 }}>
            {file ? `Selected File: ${file.name}` : "No file selected"}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleAddCampaign}
            color="primary"
            disabled={!isFormValid}
          >
            Add Campaign
          </Button>
        </DialogActions>
      </Dialog>

      {/* Campaigns Table */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          marginTop: "1%",
        }}
      >
        <TextField
          id="outlined-search"
          size="small"
          label="Search by keywords"
          type="search"
          sx={{
            width: "380px",
          }}
          onChange={(e) => setValue(e.target.value)}
        />
        <IconButton
          data-testid="search-btn"
          onClick={handleSearch}
          className="search-icon-unsubscribed"
        >
          <img
            src={searchScope}
            alt="search-icon"
            className="img-srch-automated"
          />
        </IconButton>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={() => setOpenDialog(true)} // Open dialog
        >
          Add Campaign
        </Button>
        {/* <Button
                    variant="contained"
                    size="medium"
                    className="save_changes usbtn-gld"
                    disabled={disbleBtn}
                    onClick={handleSaveupdateSwitch}
                    data-testid="save-btn"
                  >
                    Save Changes
                  </Button> */}
      </Box >
      </Box>
      <Box mt={3} className="custom-srch-btn">
        {!loaderStore ? (
          <Paper
            sx={{
              width: "100%",
              overflow: "hidden",
              border: "1px solid #DBDBDB",
              borderRadius: "0",
            }}
          >
            <TableContainer sx={{ maxHeight: 358 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead >
                  <TableRow hover role="checkbox" className="custom-header-color">
                    <TableCell>Name</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>File Name</TableCell>
                    <TableCell>Created Date</TableCell>
                    <TableCell>Audience Count</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ backgroundColor: "#F6F8FA" }}>
                {/* {campaigns
  .filter((campaign) => campaign.status === "active") // Filter only active campaigns
  .map((campaign) => ( */}
   {campaigns.map((campaign) => (
    <TableRow key={campaign.id}>
      <TableCell>{campaign.name}</TableCell>
      <TableCell>{campaign.description}</TableCell>
      <TableCell>{campaign.filename}</TableCell>
      <TableCell>
        {moment(campaign.created_at).format("MM/DD/YYYY")}
      </TableCell>
      <TableCell>{campaign.audience_count}</TableCell>
      <TableCell>
        <Chip
          label={campaign.status}
          color={campaign.status === "active" ? "success" : "default"}
          size="small"
        />
      </TableCell>
      <TableCell>
        <Tooltip title="View Details">
          <IconButton
            color="primary"
            onClick={() =>
              navigate(`/contacts/${campaign.id}`, {
                state: { campaign },
              })
            }
          >
            <Visibility />
          </IconButton>
        </Tooltip>
        {/* Uncomment and use these buttons if needed */}
        {/* <Tooltip title={`Mark as ${campaign.status === "active" ? "Inactive" : "Active"}`}>
          <IconButton
            color={campaign.status === "active" ? "secondary" : "primary"}
            onClick={() =>
              handleToggleCampaignStatus(campaign.id, campaign.status === "active" ? "inactive" : "active")
            }
          >
            {campaign.status === "active" ? <BlockIcon /> : <CheckCircleIcon />}
          </IconButton>
        </Tooltip> */}
        <Tooltip title="Delete Campaign">
          <IconButton
            color="secondary"
            onClick={() => openConfiramtionModal(campaign.id)}
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {pageSize < 2 ? (
              ""
            ) : (
              <Box className="table-pagination">
                <Pagination
                  count={totalContactsNumber}
                  pageSize={pageSize}
                  handleNext={handleNext}
                  page={page}
                />
              </Box>
            )}
          </Paper>
        ) : (
          <Loader />
        )}
      </Box>
      <ConfirmationDialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        onConfirm={() =>
          selectedCampaignId && handleDeleteCampaign(selectedCampaignId)
        }
        title="Delete Campaign"
        message={`Are you sure you want to delete this campaign?`}
      />
    </Box>
  );
};

export default Campaigns;