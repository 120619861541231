import * as React from "react";
import Table from "@mui/material/Table";
import Grid from "@mui/material/Grid";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import "./UnsubscribedFollowUp.css";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import searchScope from "../../Assets/Icons/searchScope.svg";
import { TextField, IconButton, Box } from "@mui/material";
import { toast } from "react-toastify";
import Loader from "../../Common/Loader/Loader";
import { setLoader } from "../../Services/Actions/actionCreators";
import {
  fetchUnsubscribedUsersFollowUp,
  searchUnsubscribeContacts,
  updateUnsubscribedUsersFollowUp,
} from "../../Services/Actions/actions";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../Common/Pagination/Pagination";
interface UnsubscriptionSettingsProps {
  isTesting?: boolean;

}
export const UnsubscribedFollowUp: React.FC<UnsubscriptionSettingsProps> = ({ isTesting }) => {
  const loaderStore = useSelector((state: any) => state.loaderReducer?.loader);
  const dispatch = useDispatch();

  const ToogleButton = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      "&:before, &:after": {
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 10,
        height: 10,
      },
      "&:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      "&:after": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 11,
      height: 11,
      margin: 2,
    },
  }));

  const [unsubscribedUsers, setUnsubscribedUsers] = React.useState<any[]>([]);
  let [disbleBtn, setDisbleBtn] = React.useState<boolean>(true);
  const [pageIndex, setPageIndex] = React.useState(0);
  const [value, setValue] = React.useState("");
  const [totalContactsNumber, setTotalContactsNumber] = React.useState(0);
  const [page, setPage] = React.useState(0);

  let pageSize = 5;

  const handleSaveupdateSwitch = () => {
    updateUnsubscribedUsersFollowUp(unsubscribedUsers);
    toast.success("Changes Saved successfully.");
  };

  const handleSwitchChange = (e: any, index: number) => {
    let contactClone = [...unsubscribedUsers];
    contactClone[index].Subscribed = e?.target?.checked;
    setUnsubscribedUsers(contactClone);
    setDisbleBtn(false);
  };

  const handleSearch = async () => {
    if (value) {
      dispatch(setLoader(true));
      await searchUnsubscribeContacts(value).then((res: any) => {
        setUnsubscribedUsers(res?.data);

        setTotalContactsNumber(res?.data?.length);
      });
      dispatch(setLoader(false));
    } else {
      dispatch(setLoader(true));
      fetchUnsubscribedUsersFollowUp({
        cursor: pageIndex,
        offset: pageSize,
      }).then((res: any) => {
        setUnsubscribedUsers(res?.data?.records);
        setTotalContactsNumber(res?.data?.total_records);
      });
      dispatch(setLoader(false));
    }
  };

  const handleNext = (event: any, newPage: number) => {
    setPageIndex(newPage);
    setPage(newPage);
  };

  const fetchData = async () => {
    try {
      dispatch(setLoader(true));
      await fetchUnsubscribedUsersFollowUp({
        cursor: pageIndex,
        offset: pageSize,
      }).then((res: any) => {
        setUnsubscribedUsers(res?.data?.records);
        setTotalContactsNumber(res?.data?.total_records);
      });
      dispatch(setLoader(false));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [pageIndex]);
  React.useEffect(() => {
    if (isTesting) {
      handleSaveupdateSwitch();
      
      handleSearch()
      handleNext("", 8)
      fetchData()
    }
  }, [])
  return (
    <>
      <Box>
        <CssBaseline />
        <Container className="cust-frame-parent-UF">
          <Grid container spacing={2}>
            <Grid item xs={20} className="custom-hd-space">
              <Box className="custom-btn-res" sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box className="custom-hd-unsub"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h2 style={{ height: "26px", color: "#000000" }}>
                    Unsubscribed Contacts
                  </h2>
                </Box>
                <Box className="custom-serch-icon">
                <Box
                
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "1% 0",
                  }}
                >
                  <TextField
                  className="search-box2"
                    id="outlined-search"
                    size="small"
                    label="Search by keywords"
                    type="search"
                  
                    onChange={(e) => setValue(e.target.value)}
                  />
                  <IconButton
                    data-testid="search-btn"
                    onClick={handleSearch}
                    className="search-icon-unsubscribed"
                  >
                    <img
                      src={searchScope}
                      alt="search-icon"
                      className="img-srch-automated"
                    />
                  </IconButton>
                  </Box>
                  <Button
                    variant="contained"
                    size="medium"
                    className="save_changes usbtn-gld"
                    disabled={disbleBtn}
                    onClick={handleSaveupdateSwitch}
                    data-testid="save-btn"
                  >
                    Save Changes
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={20}>
              {!loaderStore ? (
                <Paper
                  sx={{
                    width: "100%",
                    overflow: "hidden",
                    border: "1px solid #DBDBDB",
                    borderRadius: "0",
                  }}
                >
                  <TableContainer sx={{ maxHeight: 648 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow hover role="checkbox" className="header-color">
                          <TableCell
                            style={{
                              fontWeight: "bold",
                              fontSize: "16px",
                              borderBottom: "1px solid #CCCCCC",
                            }}
                          >
                            Contacts
                          </TableCell>
                          <TableCell sx={{ borderBottom: "1px solid #CCCCCC" }}>
                            {"      "}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody style={{ backgroundColor: "#F6F8FA" }}>
                        {unsubscribedUsers?.map((item, index) => (
                          <TableRow key={item.id} hover role="checkbox">
                            <TableCell
                              sx={{ borderBottom: "1px solid #CCCCCC" }}
                            >
                              <span
                                style={{ fontWeight: "bold", color: "#343434" }}
                              >
                                {item["Name"]}
                              </span>
                              <br />
                              <span style={{ color: "#595959" }}>
                                {item["Email"]}
                              </span>
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{ borderBottom: "1px solid #CCCCCC" }}
                            >
                              <ToogleButton
                                className="custom-colors"
                                checked={item?.Subscribed}
                                onClick={(e) => handleSwitchChange(e, index)}
                                data-testid="toggle-btn"
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {pageSize < 2 ? (
                    ""
                  ) : (
                    <Box className="table-pagination">
                      <Pagination
                        count={totalContactsNumber}
                        pageSize={pageSize}
                        handleNext={handleNext}
                        page={page}
                      />
                    </Box>
                  )}
                </Paper>
              ) : (
                <Loader />
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
