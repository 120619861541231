 /* istanbul ignore file */
import React from "react";
import Chart from "react-apexcharts";
import { TRACKER_API_ENDPOINTS } from "../../config/auth"

interface State {
  series: { name: string; data: number[] }[];
  options: any;
}

class LineChart extends React.Component<{}, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      series: [],
      options: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: 'Email Campaign Metrics by Week',
          align: 'left'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          },
        },
        xaxis: {
          categories: [],
        },
        legend: {
          onItemHover: {
            highlightDataSeries: true
          },
          onItemClick: {
            toggleDataSeries: true
          },
        }
      },
    };
  }

  componentDidMount() {
    fetch(TRACKER_API_ENDPOINTS.EVENT_REPORT)
      ?.then(response => response.json())
      ?.then(data => this.updateChart(data))
      ?.catch(error => console.error("Error fetching data:", error));
  }



  updateChart = (data: any) => {
    const allWeeks = Object.keys(data).sort();
    const seriesData: { [key: string]: number[] } = {};

    allWeeks.forEach(week => {
      const values = data[week] || {};
      Object.entries(values).forEach(([key, value]: [string, unknown]) => {
        if (['send', 'click', 'open', 'delivered'].includes(key)) {
          if (!seriesData[key]) {
            seriesData[key] = Array(allWeeks.length).fill(0);
          }
          if (typeof value === 'number') {
            seriesData[key][allWeeks.indexOf(week)] = 0;
          }
        }
      });
    });

    const series = Object.entries(seriesData).map(([name, data]) => ({
      name: this.formatLegendName(name),
      data
    }));

    this.setState({
      series,
      options: {
        ...this.state.options,
        xaxis: {
          categories: allWeeks
        }
      }
    });
  };



  formatLegendName = (name: string) => {
    return name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };



  render() {
    return (
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart options={this.state.options} series={this.state.series} type="line" height={350} />
          </div>
        </div>
      </div>
    );
  }
}

export default LineChart;